import axios from 'axios';

import { UserUnauthenticatedException } from '@/exceptions/userUnauthenticatedException';

export interface MeResponse {
  id: string;
  displayName: string | undefined;
  photoUrl: string | undefined;
  loginMethod: string | undefined;
  featureFlags: Record<string, string> | undefined;
  tosAcceptance: { tosVersion: '1.0'; acceptedOn: unknown };
  email?: string;
}

export class UserService {
  async getMe(): Promise<MeResponse | undefined> {
    const url = new URL('users/me', `${process.env.VUE_APP_API_URL}`);
    return this.getMeRaw(url.href);
  }

  async logout(): Promise<void> {
    const url = `${process.env.VUE_APP_API_URL}logout`;
    await axios.get(url, {
      withCredentials: true,
    });
  }

  async getRptMe(): Promise<MeResponse | undefined> {
    const url = `${process.env.VUE_APP_RPT_API_URL}users/me`;
    return this.getMeRaw(url);
  }

  async logoutRpt(): Promise<void> {
    const url = `${process.env.VUE_APP_RPT_API_URL}logout`;
    await axios.get(url, {
      withCredentials: true,
    });
  }

  private async getMeRaw(url: string) {
    try {
      const resp = await axios.get(url, {
        withCredentials: true,
      });

      if (resp) {
        if (resp.status === 200 && resp.data) {
          return resp.data;
        } else {
          console.log('On /me, got non 200 response: ' + resp.status, resp.data);
          if (resp.status === 401) {
            throw new UserUnauthenticatedException();
          }
        }
      }
      return undefined;
    } catch (e) {
      console.log('Error on get /me', e);
      return undefined;
    }
  }
}
