






























































































import 'firebase/firestore';
import 'firebase/auth';
import 'vue-loading-overlay/dist/vue-loading.css';

import axios from 'axios';
import firebase from 'firebase/app';
import gql from 'graphql-tag';
import moment from 'moment';
import { Component as VueComponent } from 'vue';
import Component from 'vue-class-component';
import Loading from 'vue-loading-overlay';
import { Watch } from 'vue-property-decorator';

import StatusIndicator from '@/components/status/StatusIndicator.vue';
// import { RealtimeService } from '@/realtime/realtimeService';
import { AuthService } from '@/services/authService';
import { IntercomService, IntercomSettings } from '@/services/intercomService';
import { UserService } from '@/services/userService';

import { baConfig } from '../config';
import { Org } from './api-svc-types';
import { BaseVue } from './BaseVue';
import Navigation from './components/Navigation.vue';
import OnboardSelfSignedUser from './components/onboarding/OnboardSelfSignedUser.vue';
import OrgJoins from './components/org/Join.vue';
import OrgDisabled from './components/OrgDisabled.vue';
import TermsOfService from './components/termsOfService/TermsOfService.vue';
import Timer from './components/Timer.vue';
import Tooltip from './components/tooltip/Tooltip.vue';
import TrialExpired from './components/TrialExpired.vue';
import UiAlert from './components/ui/UiAlert.vue';
import UiModal from './components/ui/UiModal.vue';
import { InactivityWatcher, InactivityWatcherEvent } from './inactivityWatcher';
import { MUT_SNACKBAR } from './store';
import { requestParentToKeepActive } from './utils/iframeMessageRequester';

@Component({
  components: {
    StatusIndicator,
    OrgJoins: OrgJoins,
    Loading: Loading as VueComponent, // <- Cast to VueComponent due to type-def error in the plugin's code
    Navigation,
    OnboardSelfSignedUser,
    Timer,
    TermsOfService,
    TrialExpired,
    OrgDisabled,
    UiAlert,
    UiModal,
    Tooltip,
  },
})
export default class App extends BaseVue {
  private readonly us = new UserService();
  private readonly intercom = new IntercomService();

  get isLoading(): boolean {
    return this.$store.state.isLoading;
  }

  public get trialExpiry() {
    return this.currentOrganization?.trialExpiry ? new Date(this.currentOrganization.trialExpiry) : undefined;
  }

  get user() {
    return this.$store.state.user;
  }

  get photoUrl() {
    return this.$store.state.user?.photoUrl;
  }

  get currentOrganization(): Org | undefined {
    return this.$store.state.currentOrg;
  }

  get isTrial(): boolean {
    return !!this.currentOrganization?.trialExpiry;
  }

  firebase_app: typeof firebase | null = null;
  snackbarText = '';
  snackbarShow = false;
  snackbarColor = 'success';
  snackbarHeading = null as string | null;
  snackbarTimeout = 10000;
  trialExpired = false;
  isOrgDisabled = false;

  inactivityWatcher!: InactivityWatcher;
  logoutTimerElapsed = 0;
  logoutTimerDuration = 120;
  showLogoutWarning = false;
  showTermsOfService = false;

  // private realtime: RealtimeService = new RealtimeService();

  //  this is a temp variable, remove this when we remove the timer
  //  do not use this anywhere else
  private tokenKeepAliveTimer: ReturnType<typeof setInterval> | undefined = undefined;

  public get orgs(): Org[] {
    return this.$store.state.orgs;
  }

  get isEmbedded(): boolean {
    return window.self !== window.top;
  }

  created() {
    this.firebase_app = firebase;
    this.$store.watch(
      (state) => state.snackbar,
      () => {
        const snack = this.$store.state.snackbar;
        if (snack) {
          this.snackbarShow = true;
          this.snackbarHeading = snack.heading ?? null;
          this.snackbarText = snack.message;
          this.snackbarColor = snack.color;
          this.snackbarTimeout = snack.timeout ?? 10000;
          this.$store.commit(MUT_SNACKBAR, undefined);
        }
      }
    );
    this.startManifestValidation();
  }

  async startManifestValidation(): Promise<void> {
    const currentCommit = await this.getManifestCommit();
    setInterval(async () => {
      const newCommit: string = await this.getManifestCommit();
      if (currentCommit !== newCommit && confirm('A new update is available for your bitwave client, refresh now?'))
        location.reload();
    }, moment.duration(10, 'minutes').asMilliseconds());
  }

  async getManifestCommit() {
    const manifestUrl = `${process.env.VUE_APP_BASE_URL}/manifest.json`;
    return (await (await fetch(manifestUrl)).json()).commit;
  }

  async acceptTOS() {
    const updateUser = this.$store.state.user;

    if (!updateUser) throw new Error('User not available');

    const updateData = { tosAcceptance: { tosVersion: '1.0', acceptedOn: moment().unix() } };

    const resp = await this.$apollo.mutate({
      // Query
      mutation: gql`
        mutation ($userId: ID!, $user: UserInput!) {
          updateUser(userId: $userId, user: $user) {
            success
            errors
          }
        }
      `,
      // Parameters
      variables: {
        userId: updateUser.id,
        user: updateData,
      },
    });
    this.showTermsOfService = false;
  }

  cancelTOS() {
    this.showTermsOfService = false;
    this.$router.push('/signout');
  }

  mounted() {
    if (baConfig.disableInactivityWatcher) {
      console.warn('Inactivity watcher disabled by config');
      return;
    }

    if (this.isEmbedded) {
      // if an iframe send all activity to parent and let parent handle everything else
      for (const event of InactivityWatcher.focusEvents) {
        document.addEventListener(event, () => {
          requestParentToKeepActive(event);
        });
      }
      return;
    }

    this.inactivityWatcher = InactivityWatcher.initialize({
      giveWarningAt: 2 * 60 * 1000,
      maxInactiveTime: 90 * 60 * 1000,
    });
    this.inactivityWatcher.addEventListener(InactivityWatcherEvent.Active, () => {
      if (this.showLogoutWarning) {
        this.showLogoutWarning = false;
      }
    });
    this.inactivityWatcher.addEventListener(InactivityWatcherEvent.Warning, () => {
      this.showLogoutWarning = true;
      this.logoutTimerElapsed = 0;
    });
    this.inactivityWatcher.addEventListener(InactivityWatcherEvent.Inactive, () => {
      this.showLogoutWarning = false;
      this.$router.push('/signout');
    });

    this.logoutTimerDuration = this.inactivityWatcher.giveWarningAt / 1000;
  }

  async logOut() {
    //  we can remove this after we remove the timer
    if (this.tokenKeepAliveTimer) {
      clearInterval(this.tokenKeepAliveTimer);
      this.tokenKeepAliveTimer = undefined;
    }

    try {
      const authService = new AuthService();
      await authService.logout();
      console.log('Completed logging out successfully');
    } catch (err) {
      console.log('Problem logging out', err);
    }
  }

  async staySignedIn() {
    this.inactivityWatcher.setActive();
    await axios.get(`${process.env.VUE_APP_API_URL}users/me`, {
      withCredentials: true,
    });

    //  this is a short term solution to allow the app to auth against report service
    //  it is meant to be replaced once we implement api gateway
    //  call this every 5 minutes
  }

  get intercomOptions() {
    if (!this.user) return;

    const options: IntercomSettings = {
      user_id: this.user.id,
      email: this.user.email,
      name: this.user.displayName,
    };

    if (this.currentOrganization?.id) {
      options.company = {
        id: this.currentOrganization.id,
        name: this.currentOrganization.name ?? '',
      };
    }

    return options;
  }

  setIntercomVisibility(hidden: boolean) {
    if (!hidden && this.intercomOptions) this.intercom.boot(this.intercomOptions);
    else this.intercom.shutdown();
  }

  @Watch('user')
  async onUserChange(user: {
    id: string;
    displayName: string;
    email: string;
    emailPrefs: unknown;
    tosAcceptance: unknown;
  }) {
    const isUserDefined = !!user;

    if (this.inactivityWatcher) {
      this.inactivityWatcher.enabled = isUserDefined;
    }

    if (isUserDefined && !user?.tosAcceptance) this.showTermsOfService = true;

    if (!this.intercom.isBooted && this.intercomOptions) {
      this.intercom.boot(this.intercomOptions);
    } else if (!isUserDefined) {
      this.intercom.shutdown();
    }
  }

  @Watch('currentOrganization')
  async onOrgChange(org?: Org) {
    if (org?.id) {
      if (this.intercomOptions) {
        this.intercom.reboot(this.intercomOptions);
      }

      if (org.flags) {
        const flags: Record<string, string> = {};
        for (const f of org.flags) {
          if (f) {
            flags[f.key] = f.value;
          }
        }

        // await this.realtime.orgChanged(org.id, flags);
      }

      // load subsidiaries from endpoint and keep in store
      this.$store.dispatch('subsidiaries/getSubsidiaries', this.$store.state.currentOrg.id);
    }
  }
}
