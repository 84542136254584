import { baConfig } from '../../../../config';
import { OrganizationsApi } from '../../../../generated/api-svc/api';
import { MUT_ERR_SUBS, MUT_GET_SUBS, MUT_SET_SUBS } from './types';

const state = () => ({
  subsidiaries: [],
  isLoading: null | false,
});

const getters = {
  SUBSIDIARIES: (state) => {
    return state.subsidiaries;
  },
  SUBSIDIARIES_ISLOADING: (state) => {
    return state.isLoading;
  },
};

const actions = {
  async getSubsidiaries({ commit }, orgId) {
    try {
      const orgsApi = new OrganizationsApi(undefined, baConfig.getFriendlyApiUrl());
      const resp = await orgsApi.listSubsidiaries(orgId, { withCredentials: true });
      if (resp.status === 200) {
        commit(MUT_SET_SUBS, resp.data ?? []);
      }
    } catch (error) {
      commit(MUT_ERR_SUBS, error);
    }
  },
};

const mutations = {
  [MUT_GET_SUBS](state) {
    state.isLoading = true;
  },
  [MUT_SET_SUBS](state, subsidiaries) {
    state.subsidiaries = subsidiaries;
    state.isLoading = false;
  },
  [MUT_ERR_SUBS](state) {
    state.subsidiaries = undefined;
    state.isLoading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
