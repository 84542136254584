import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import Cookies from 'js-cookie';

import { AuthService } from '@/services/authService';

import { MUT_IS_LOADING, MUT_LOGOUT, MUT_PARENT_AUTH_TOKEN, MUT_SNACKBAR, store } from '../store';
import { requestTokenFromParent } from './iframeMessageRequester';

export interface RetryWrapperOptions {
  retries: number;
  status_code: number;
  exponentialDelay?: boolean;
  delayFactor?: number;
}

/**
 * @param axios AxiosInstance
 * @param options RetryWrapperOptions
 */
export const axiosRetryWrapper = (baseURL: string, options: RetryWrapperOptions): AxiosInstance => {
  // const st = new Date().valueOf();
  // console.log(new Date(st));
  // console.time('retry');
  const createConfig: AxiosRequestConfig = { baseURL };
  const client = axios.create(createConfig);

  axiosRetry(client, {
    // retries: options.retries,
    retryDelay: (retryCount, error) => {
      // console.log(retryCount, '>> retry count <<');
      // console.log('>> run after x sec: <<', new Date(), (new Date().valueOf() - st) / 1000);
      const delayFactor = options.delayFactor ?? 1000; // 1 sec
      return options.exponentialDelay ? axiosRetry.exponentialDelay(retryCount) : retryCount * delayFactor;
    },
    retryCondition: (error) => {
      return error.response?.status === options.status_code;
    },
    // onMaxRetryTimesExceeded: (error, retryCount) => {
    //   console.log(error, retryCount + 1, 'finished');
    //   console.timeEnd('retry');
    //   console.log(retryCount, '>> retry count <<');
    //   console.log('>> run after total x sec: <<', new Date(), (new Date().valueOf() - st) / 1000);
    // },
  });

  // activate interceptor configs for request | response
  client.interceptors.request.use(axiosInterceptorReqConfig);
  client.interceptors.response.use(axiosInterceptorResHandler);

  return client;
};

export const isAuthEmbedded = (): boolean => {
  const hash = window.location.hash;
  const hashSearchParams = hash.includes('?') ? new URLSearchParams(hash.split('?')[1]) : new URLSearchParams();
  const isAuthEmbedded = hashSearchParams.get('embedded') === 'true';
  return isAuthEmbedded;
};

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/** common methods used in main interceptor and axios retry wrapper **/
export const axiosInterceptorReqConfig = async (config: AxiosRequestConfig) => {
  if (window.self !== window.top && !isAuthEmbedded()) {
    const parentToken = await requestTokenFromParent();
    store.commit(MUT_PARENT_AUTH_TOKEN, parentToken);
    const accessToken = parentToken || store.state.authTokens?.accessToken.token;
    if (config.headers && accessToken && config.url) {
      const url = new URL(config.url);
      const origin = url.origin;
      const injectTokensInto = [
        process.env.VUE_APP_API_URL,
        process.env.VUE_APP_RPT_API_URL,
        process.env.VUE_APP_API_2_SVC_URL,
        process.env.VUE_APP_API3_URL,
        process.env.VUE_APP_TXN_SVC_URL,
      ];
      const origins = new Set(injectTokensInto.filter((x): x is string => !!x).map((x) => new URL(x).origin));
      if (origins.has(origin)) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
  } else {
    const accessToken = store.state.authTokens?.accessToken.token ?? Cookies.get('__session');

    if (config.headers && accessToken && config.url) {
      const url = new URL(config.url);
      const origin = url.origin;
      const injectTokensInto = [
        process.env.VUE_APP_API_URL,
        process.env.VUE_APP_RPT_API_URL,
        process.env.VUE_APP_API_2_SVC_URL,
        process.env.VUE_APP_API3_URL,
        process.env.VUE_APP_TXN_SVC_URL,
      ];
      const origins = new Set(injectTokensInto.filter((x): x is string => !!x).map((x) => new URL(x).origin));
      if (origins.has(origin)) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
  }

  return config;
};

export const axiosInterceptorResHandler = <T, D>(res: AxiosResponse<T, D>): Promise<AxiosResponse<T, D>> => {
  const isAuthPage = window.location.hash.includes('auth');
  const statusCode = res.status;
  const resData = res.data as any;

  // Log response details for debugging
  // console.log(`API Response [${statusCode}]:`, {
  //   url: res.config.url,
  //   method: res.config.method,
  //   status: statusCode,
  //   timestamp: new Date().toISOString()
  // });

  // Handle successful responses
  if (statusCode === 200) {
    return Promise.resolve(res);
  }

  // Handle various error scenarios
  switch (statusCode) {
    case 401: // Unauthorized
    case 403: // Forbidden
      if (isAuthPage || (statusCode === 403 && isAuthPage)) {
        handleAuthError();
      }
      break;

    case 500:
      if (resData?.message === 'Unexpected error Missing bearer token') {
        handleAuthError();
      }
      break;

    case 429: // Too Many Requests
      console.warn('Rate limit exceeded:', res.config.url);
      store.commit(MUT_SNACKBAR, {
        color: 'warning',
        message: 'Too many requests. Please try again later.',
      });
      break;

    default:
      // Handle any other error status codes
      if (statusCode >= 400) {
        store.commit(MUT_SNACKBAR, {
          color: 'error',
          message: resData?.message || 'An unexpected error occurred',
        });
      }
  }

  return Promise.resolve(res);
};

// Helper function to handle authentication errors
function handleAuthError() {
  console.log('Authentication error detected - logging out user');

  const authService = new AuthService();
  authService
    .logout()
    .catch((e) => console.error('Logout failed:', e))
    .finally(() => {
      store.commit(MUT_LOGOUT);
      store.commit(MUT_IS_LOADING, false);

      // Redirect to auth page
      const baseUrl = process.env.VUE_APP_BASE_URL || '';
      window.location.href = `${baseUrl}/#/auth`;
    });
}
