

































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import UiFormLabel from './UiFormLabel.vue';
import UiListItem from './UiListItem.vue';
import UiLoading from './UiLoading.vue';
import UiTextEdit from './UiTextEdit.vue';

@Component({ components: { UiFormLabel, UiListItem, UiTextEdit, UiLoading } })
export default class UiSelect2 extends Vue {
  @Prop({ required: true })
  public readonly items!: any[];

  @Prop({ default: 'id' })
  public readonly keyField!: string;

  @Prop({ default: 'label' })
  public readonly labelField!: string;

  @Prop({ default: 'isStatic' })
  public readonly permanentField!: string;

  @Prop({ default: true })
  public readonly isValid?: boolean;

  @Prop()
  public readonly showValidationIcon?: boolean;

  @Prop()
  public readonly validationMessage?: string;

  @Prop()
  public readonly showValidMark?: boolean;

  @Prop({ required: true })
  public readonly value!: any | any[];

  @Prop({ default: false })
  public readonly multiple!: boolean;

  @Prop({ default: false })
  public readonly searchable!: boolean;

  @Prop({ default: '' })
  public readonly placeholder!: string;

  @Prop({ default: '' })
  public readonly label!: string;

  @Prop({ default: '' })
  public readonly subLabel!: string;

  @Prop({ default: false })
  public readonly disabled!: boolean;

  @Prop({ default: false })
  public readonly readonly!: boolean;

  @Prop({ default: false })
  public readonly retainFocus!: boolean;

  @Prop({ default: false })
  public readonly keyOnly!: boolean;

  @Prop({ default: '191px' })
  public readonly maxScrollHeight!: string;

  @Prop({ default: 'icon' })
  public readonly iconField!: string;

  @Prop({ default: 'image' })
  public readonly imageField!: string;

  @Prop({ default: 'caption' })
  public readonly captionField!: string;

  @Prop({ default: false })
  public readonly hideDropdownIcon!: boolean;

  @Prop({ default: false })
  public readonly centered!: boolean;

  @Prop({ default: false })
  public readonly centeredList!: boolean;

  @Prop({ default: 'sm' })
  public readonly fontSize!: string;

  @Prop({ default: false })
  public readonly staticDisplay!: boolean;

  @Prop({ default: '' })
  public readonly staticValueIcon!: string;

  @Prop({ default: '' })
  public readonly staticValueLabel!: string;

  @Prop({ default: '' })
  public readonly listWidth!: string;

  @Prop({ default: '' })
  public readonly listAlign!: string;

  @Prop({ default: 'bottom' })
  public readonly verticalListAlign!: 'top' | 'bottom';

  @Prop({ default: false })
  public readonly checkboxes!: boolean;

  @Prop({ default: 2 })
  public readonly maxBadges!: number;

  @Prop({ default: false })
  public readonly alwaysVisible!: boolean;

  @Prop({ default: false })
  public readonly clearable!: boolean;

  @Prop({ default: 'Search...' })
  public readonly searchPlaceholder!: string;

  @Prop({ default: true })
  public readonly validated?: boolean;

  @Prop({ default: undefined })
  public readonly listItemTestId?: string;

  @Prop({ default: false })
  public readonly loading!: boolean;

  @Prop({ default: false })
  public readonly upsert!: boolean;

  public search = '';

  public isOpen = false;

  public isDirty = false;

  public showUpsertHint = false;

  public get compatibleItems() {
    return this.items.map((item) => {
      if (typeof item === 'string' || typeof item === 'number') {
        return { [this.keyField]: item, [this.labelField]: item, [this.iconField]: null };
      }
      return item;
    });
  }

  public get compatibleValue() {
    let value = this.value;
    if (this.multiple) {
      value = this.value || [];
    } else {
      value = this.value ?? '';
    }
    if (typeof value === 'string' || typeof value === 'number') {
      value = { [this.keyField]: value, [this.labelField]: value, [this.iconField]: null };
    } else if (Array.isArray(value)) {
      value = value.map((item) => {
        if (typeof item === 'string' || typeof item === 'number') {
          return { [this.keyField]: item, [this.labelField]: item, [this.iconField]: null };
        }
        return item;
      });
    }
    return value;
  }

  public get displayBadges() {
    return this.compatibleValue.slice(0, this.maxBadges);
  }

  public get filteredItems(): any[] {
    const filterValues = (this.compatibleItems ?? []).filter((item) => {
      return item[this.labelField]?.toLowerCase().includes(this.search.toLowerCase());
    });

    this.showUpsertHint = this.upsert && !filterValues.length;

    return filterValues;
  }

  public clear() {
    this.$emit('input', this.multiple ? [] : '');
  }

  public get selectedValueLabel() {
    if (this.multiple) return '';
    return this.getItemLabel(this.compatibleValue);
  }

  public get selectedValueIcon() {
    if (this.multiple) return '';
    return this.getItemIcon(this.compatibleValue);
  }

  public get selectedValueImage() {
    if (this.multiple) return '';
    return this.getItemImage(this.compatibleValue);
  }

  public get inputValue() {
    if (!this.search && !this.isOpen) {
      return this.selectedValueLabel;
    } else return this.search;
  }

  public getItemLabel(item: any) {
    return this.keyOnly
      ? (this.compatibleItems.find((x) => x[this.keyField] === item[this.keyField]) ?? {})[this.labelField] ?? ''
      : item[this.labelField];
  }

  public getItemIcon(item: any) {
    return this.keyOnly
      ? (this.compatibleItems.find((x) => x[this.keyField] === item[this.keyField]) ?? {})[this.iconField] ?? ''
      : item[this.iconField];
  }

  public getItemImage(item: any) {
    return this.keyOnly
      ? (this.compatibleItems.find((x) => x[this.keyField] === item[this.keyField]) ?? {})[this.imageField] ?? ''
      : item[this.imageField];
  }

  public async setOpen(value: boolean) {
    this.isOpen = value;
    await this.$nextTick();
    if (value) {
      this.search = '';
      this.isDirty = false;
    } else {
      this.search =
        this.searchable && !this.multiple ? this.getItemLabel(this.compatibleValue)[this.labelField] ?? '' : '';
      this.isDirty = true;
    }
  }

  public selectItem(item: any) {
    const itemValue = (x: any) => (this.keyOnly ? x[this.keyField] : x);
    if (this.multiple) {
      if (this.compatibleValue?.find((x: any) => item[this.keyField] === x[this.keyField])) {
        this.$emit(
          'input',
          this.compatibleValue
            ?.filter((x: any) => item[this.keyField] !== x[this.keyField])
            .map((x: any) => (this.keyOnly ? x[this.keyField] : x))
        );
      } else {
        this.$emit('input', [...(this.value || []), itemValue(item)]);
      }
    } else {
      this.$emit('input', itemValue(item));
    }
    if (!this.retainFocus) this.setOpen(false);

    this.$emit('changed', true);
  }

  public isSelected(item: any) {
    if (this.multiple) {
      return !!this.compatibleValue?.find((x: any) => item[this.keyField] === x[this.keyField]);
    } else if (this.compatibleValue) {
      return this.compatibleValue[this.keyField] === item[this.keyField];
    }
    return false;
  }

  public onFocus() {
    if (!this.disabled) this.setOpen(true);
  }

  public onEnterKey(event: unknown) {
    this.$emit('enterKey', event);
    this.showUpsertHint = false;
  }

  public scrolling = false;
  public mouseDown = false;

  public onMouseDown() {
    this.mouseDown = true;
  }

  public onScroll() {
    if (this.mouseDown) {
      this.scrolling = true;
    }
  }

  public onMouseUp() {
    this.mouseDown = false;
    if (!this.scrolling && this.delayedFocusEvent) {
      this.handleOutsideFocus(this.delayedFocusEvent);
      this.delayedFocusEvent = null;
    }
    this.scrolling = false;
  }

  public delayedFocusEvent = null as FocusEvent | null;

  handleOutsideFocus(event: FocusEvent) {
    // Check if the focus event occurred outside of the component
    if (!(this.$refs.selectContainer as HTMLElement)?.contains(event.target as Node)) {
      if (!this.mouseDown) this.setOpen(false);
      else {
        this.delayedFocusEvent = event;
      }
    }
  }

  handleOutsideClick(event: MouseEvent) {
    // Check if the click event occurred outside of the component
    if (!(this.$refs.selectContainer as HTMLElement)?.contains(event.target as Node)) {
      this.setOpen(false);
    }
  }

  mounted() {
    // Add a focusin/click event listener to the document
    document.addEventListener('focusin', this.handleOutsideFocus);
    document.addEventListener('click', this.handleOutsideClick);
    window.addEventListener('mousedown', this.onMouseDown);
    window.addEventListener('mouseup', this.onMouseUp);
  }

  beforeUnmount() {
    // Remove the focusin/click event listener when the component is destroyed
    document.removeEventListener('focusin', this.handleOutsideFocus);
    document.removeEventListener('click', this.handleOutsideClick);
    window.removeEventListener('mousedown', this.onMouseDown);
    window.removeEventListener('mouseup', this.onMouseUp);
  }
}
